var RestraintquizQuestions = [
  {
      question: "Did the perpetrator lock the windows or doors where the worker was residing or working?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Was the worker monitored by the perpetrator?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Was the worker’s movement restricted in any way by the perpetrator?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator kidnap or threaten to kidnap the worker?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator hold the worker against will?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the worker have limited communication with family and/or friends?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator ever physically restrain or threaten to physically restrain the worker in any other way?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  }
];

export default RestraintquizQuestions;
