var ExtortionquizQuestions = [
  {
      question: "Did the perpetrator ever threaten to accuse the worker of a crime if he/she did not continue to work?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator ever compel injury or threaten to compel injury by use of position of power (i.e. police authority, licensing authority)?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the worker fear retaliation from the perpetrator for quitting?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator ever engage in extortion in any other way?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
];

export default ExtortionquizQuestions;
