var AbuseofLawquizQuestions = [
  {
      question: "Did the perpetrator threaten the worker with deportation?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator discuss immigration status or the lack of immigration status?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator ever threaten the worker with contacting the 'authorities,' law enforcement, or the government in order to cause the worker fear?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator ever abuse or threaten to abuse the law or legal process in any other way?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  }
];

export default AbuseofLawquizQuestions;
