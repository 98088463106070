var FinancialHarmquizQuestions = [
  {
      question: "Was the worker paid less than minimum wage?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator ever open a bank account in the worker's name?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator ever withdraw money from the worker’s bank account?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator ever obtain credit cards or other items using the worker’s identity?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the worker have a debt owed to the perpetrator?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator ever subject the worker to unreasonable deductions for items, such as food, housing, or transportation?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Was the worker paid overtime?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the worker ever fail to receive accurate paychecks or fail to be paid for all hours worked?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Were the worker’s wages ever withheld?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the worker have to pay rent or pay for other basic necessities to the perpetrator?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator ever cause or threaten to cause financial harm in any other way?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
    question: "Did the perpetrator send wages intended for the victim to other parties, such as family members?",
    answers: [
        {
            type: "Yes",
            content: "Yes"
        },
        {
            type: "No",
            content: "No"
        },
        {
            type: "Maybe",
            content: "Maybe"
        }
    ]
  }
];

export default FinancialHarmquizQuestions;
