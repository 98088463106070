var SeriousHarmquizQuestions = [
  {
      question: "Did the perpetrator physically hurt the worker or anyone else involved in the case?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator threaten to physically hurt the worker or anyone else?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator sexually assault the worker or threaten to sexually assault the worker?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator threaten the worker with deportation?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator threaten to contact the police for some sort of unlawful purpose?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator threaten to fire the worker (not for good cause)?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator threaten the worker with reputational harm?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Was the worker emotionally abused by the perpetrator?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  },
  {
      question: "Did the perpetrator seriously harm or threaten the worker with serious harm in any other way?",
      answers: [
          {
              type: "Yes",
              content: "Yes"
          },
          {
              type: "No",
              content: "No"
          },
          {
              type: "Maybe",
              content: "Maybe"
          }
      ]
  }
];

export default SeriousHarmquizQuestions;
